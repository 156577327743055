/* eslint-disable */
import {bus} from '@/main';  
import toast from '@/services/toast';
export default {
  name: 'appointment-make-favourite-popup',
  components: {},
  props: ['appointmentInfoForFAV'],
  data () {
    return {
      favFlag:this.appointmentInfoForFAV.is_favourite ? false : true,
      updateAppointmentID:this.appointmentInfoForFAV.id
    }
  },
  computed: {

  },
  mounted () {
  },
  methods: {
    closePopupOfFav() {
      bus.$emit('appointmentFavModalBus', false)
      document.body.classList.remove('popup_open_body')
    },
    confirmFavAppointment(){
      let data = {
        is_favourite : this.favFlag
      };
      this.$store.dispatch('updateAppointmentDetailsApiFun', {data:{data:data,id:this.updateAppointmentID}}).then((res) => {
        toast.success(res.data.msg);
        this.getAppointmentListAgainMethod();
        this.closePopupOfFav();
      }).catch((err) => {
        toast.error(err.data.user_msg || err.data.msg);
      })
    },
    getAppointmentListAgainMethod(){
      this.$store.dispatch('getAppointmentListApiFun').then((res)=>{
      })
    },
  }
}