/*eslint-disable */
import {bus} from '@/main';  
import { mapGetters } from 'vuex'; 
import updateAppointmentDetails from '@/components/private/update-appointment-details-modal';
import appointmentMakeFav from '@/components/private/appointment-make-favourite-popup';
export default {
  name: 'online-therapy',
  components: {
    'update-appointment-details' : updateAppointmentDetails,
    'appointment-favourite' : appointmentMakeFav
  },
  props: [],
  data () {
    return {
      appointmentListState:{},
      updateAppointmentDetailModal:false,
      updateAppointmentId:'',
      appointmentInfoForFav:null,
      appointmentFavModal:false
    }
  },
  computed: {
    ...mapGetters([
      'appointmentListOfTherapyGetters'
    ]),
    getAppointmentListMethod() {
      return this.appointmentListState = this.$store.getters.appointmentListOfTherapyGetters.data
    },
  },
  mounted () {
    this.getAppointmentListMethod
    bus.$on('updateAppointmentDetailModalBus', (data) => {
      this.updateAppointmentDetailModal = data
    });
    bus.$on('appointmentFavModalBus', (data) => {
      this.appointmentFavModal = data
    });
  },
  methods: {
    updateAppointmentDetailModalMethod(id){
      document.body.classList.add('popup_open_body')
      this.updateAppointmentDetailModal = true
      this.updateAppointmentId = id
    },
    makeAppointmentFavouriteMethod(data){
      document.body.classList.add('popup_open_body')
      this.appointmentInfoForFav = data
      this.appointmentFavModal = true
    }
  },
  watch:{
    getAppointmentListMethod() {
      return this.$store.getters.appointmentListOfTherapyGetters
    },
  }
}