/*eslint-disable */

import 'vue-datetime/dist/vue-datetime.css'

import { Datetime } from 'vue-datetime'
import ScheduleCalendar from './ScheduleCalendar.vue'
import {bus} from '@/main';
import toast from '@/services/toast';

// You need a specific loader for CSS files

export default {
  name: 'update-appointment-details-modal',
  components: {
    datetime: Datetime,
    ScheduleCalendar
  },
  props: ['updateAppointmentID'],
  data () {
    return {
      submitted:false,
      appointmentDateTime:'',
      minDatetime:new Date(),
      is_scheduled:false,
      is_scheduled_2:false,
      reason:'',
      schedule:null,
      selectedDateTime:''
    }
  },
  computed: {
  },
  mounted () {
    this.$store.dispatch('getAppointmentDetailByIdApiFun', {id:this.updateAppointmentID}).then((res)=>{
      this.selectedDateTime = res.data.data.appointment_time
      this.is_scheduled = res.data.data.is_scheduled
      this.is_scheduled_2 = res.data.data.is_scheduled_2
      this.schedule = res.data.data.doctor_detail.schedule
    }).catch((err)=>{})
  },
  methods: {
    getAppointmentListAgainMethod(){
      this.$store.dispatch('getAppointmentListApiFun').then((res)=>{
      })
    },
    updateAppointmentFormBeforeSubmit(){
      this.$validator.validateAll().then((result) => {
        if(result){
          this.submitted = true;
          let data = {
            appointment_time:this.appointmentDateTime,
            reason:this.reason,
            is_scheduled:true
          }
          if(this.is_scheduled){
            data.is_scheduled_2 = true;
          }
          this.$store.dispatch('updateAppointmentDetailsApiFun', {data:{data:data,id:this.updateAppointmentID}}).then((res)=>{
            let notificationPayload = { 
              type:'appointment_rescheduled',
              data_id:this.updateAppointmentID,
              message:"Appointment has been rescheduled"
            }
            this.$store.dispatch('postNotificationFunc', notificationPayload)
            toast.success(res.data.msg);
            this.getAppointmentListAgainMethod()
            this.closeUpdateAppointmentDetailModal()           
            this.submitted =  false
          }).catch((err)=>{
            toast.error(err.data.user_msg || err.data.msg);
            this.submitted =  false
          })
        return;
        }
      })
    },
    closeUpdateAppointmentDetailModal(){
      bus.$emit('updateAppointmentDetailModalBus', false)
      document.body.classList.remove('popup_open_body')
    }
  }
}